import React, { useEffect, useState } from "react";
import ContactNow from './ContactNow';
import InFooter from './InFooter';
import FeatureGraph from './FeatureGraph';
import HeaderTron from './HeaderTron';
import { useTranslation } from 'react-i18next';

const DataAnalysis = () => {
 
  const { t, i18n } = useTranslation();
  
  return (
    <div>
       
        <HeaderTron color={'#FBACAC'} video="https://videodelivery.net/291031acb5e99f05e5ed258e1b23b852/manifest/video.m3u8" title={t('dataanalysis.title')} desc="" />


        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#FCDEDE'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('dataanalysis.row1.col1.title')}</b> </h2>
                            <p class="lead text-white">{t('dataanalysis.row1.col1.desc')}</p>
                        </div>
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-2"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/e382a06a-b9ce-4cf8-a411-754a3895d400/public"} width="1500"/> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#FFFFFF'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-1"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/87734d70-fa2c-4401-b106-8ae043384700/public"} width="1500"/> 
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('dataanalysis.row2.col1.title')}</b> </h2>
                            <p class="lead">{t('dataanalysis.row2.col1.desc')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

       
        <FeatureGraph image="https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/716cf7db-d87f-42af-f188-97154b5aac00/public" />

        <ContactNow bcolor="#FCE4E4" fcolor="#FBACAC"/>

        <InFooter color="#FBACAC"/>


    </div>
  );
};

export default DataAnalysis;
