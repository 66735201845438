import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';

const About = () => {
  const history = useHistory();
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  const [bgImg, setBgImg]= useState({
    backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`
  });

  const color= localStorage.getItem("color");
  useEffect(()=>{
  
    if(color === "color-1")
     setBgImg( {
      backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`
    });
    else if(color === "color-2")
    setBgImg({ backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-2/about-bg.png)`
  })
    else 
    setBgImg({backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-3/about-bg.png)`
  })
  },[color]);
 
  
  const { t, i18n } = useTranslation();

  return (
    <section id="about" className="about" style={bgImg}>
      <div className="about-decor">
        <div className="about-circle1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/team1.png`} alt="team1" />
        </div>
        <div className="about-circle2">
          <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`} alt="banner1" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="about-contain">
              <div>
                <h2 className="title">
                  {t('aboutus.about')} <span>{t('appname')} {t('app')}</span>
                </h2>
                <p align="left">
                   {t('aboutus.content1')}
                </p>
               
                <p align="left">
                  {t('aboutus.content2')}
                </p>
                <div className="row sm-mb">
                  <div className="col-6">
                    <ul className="about-style">
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <a href={`${process.env.PUBLIC_URL}/simplesetup`}>
                              <img src={`${process.env.PUBLIC_URL}/assets/images/icon1.png`} alt="easy-to-customized" />
                            </a>
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>{t('aboutus.concept1')}</h3>
                        </div>
                      </li>
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <a href={`${process.env.PUBLIC_URL}/multichannel`}>
                              <img src={`${process.env.PUBLIC_URL}/assets/images/icon3.png`} alt="multichannelmarketing" />
                            </a>
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>{t('aboutus.concept2')}</h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="about-style">
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <a href={`${process.env.PUBLIC_URL}/aestheticdesign`}>
                              <img src={`${process.env.PUBLIC_URL}/assets/images/icon2.png`} alt="Awasome-Design" />
                            </a>
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>{t('aboutus.concept3')}</h3>
                        </div>
                      </li>
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <a href={`${process.env.PUBLIC_URL}/webintegration`}>
                              <img src={`${process.env.PUBLIC_URL}/assets/images/icon4.png`} alt="web-integration" />
                            </a>
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>{t('aboutus.concept4')}</h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
          <div className="col-md-7 d-medium-none">
            <div className="about-right">
              <div className="about-phone">
                <img src={`${process.env.PUBLIC_URL}/assets/images/aboutus.png`} className="img-fluid" alt="aboutus" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
