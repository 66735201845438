import { getSession } from "../util/Common";

function apiCall(endPoint,params,callback){
    apiCallBase(endPoint,params,null,callback,1);
} export {apiCall}

function apiCallGet(endPoint,params,callback){
    apiCallBase(endPoint,params,null,callback,0);
} export {apiCallGet}

function apiCallForm(endPoint,formData,callback){
    apiCallBase(endPoint,null,formData,callback,1);
} export {apiCallForm}


function apiCallBase(endPoint,params,formData,callback,mode){

    console.log('EndPoint:', endPoint);
    console.log('API call with params:',params);  


    var langCode = 'en'
    console.log("langCode:" + langCode);
    var userId = ''
    var token = ''
    
    var headers = getSession() === null ? {
        'Accept'            : 'application/json',
        'Content-Type'      : 'application/json',
        'Accept-Language'   : langCode,   
    } : {
        'Accept'            : 'application/json',
        'Content-Type'      : 'application/json',
        'Accept-Language'   : langCode,
        'kp-user-id'        : getSession().userId === undefined ?null:getSession().userId,
        'kp-token'          : getSession().token === undefined?null:getSession().token,   
    }

    var headersForm = {
        'Accept'            : 'application/json',
        'Accept-Language'   : langCode,
    }

    var reqHeaders = (formData == null)?headers:headersForm;
    var ep = mode===1?getEndPoint(endPoint):endPoint;
    console.log("EndPoint:" + ep);
    var reqBody = (formData == null)?JSON.stringify(params):formData;
    console.log("reqBody:" + reqBody);
    
    var spec = {}

    if (mode === 1){
        spec = {
            crossDomain:true,
            method: 'POST',
            headers: reqHeaders,
            body: reqBody,
        }
    }else{
        spec = {
            crossDomain:true,
            method: 'GET',
            headers: reqHeaders,
        }
    }

    console.log("spec:" + JSON.stringify(spec));

    fetch(ep, spec)
    .then((response) => response.json())
    .then((resJson) => {
            //var responseJson = JSON.parse(resJson);
            console.log('response from fetch:',resJson);
            if (resJson.error != null){
                callback(null,resJson.error);
            }else if (resJson.errors != null){
                callback(null,resJson.errors);
            }else{

                console.log('mode:',mode);

                if (mode === 1){
                    if (resJson.result.errorCode > 0){
                        callback(null,resJson); 
                    }else{
                        callback(resJson,null);
                    }
                }else{
                    callback(resJson,null);
                }  
            }
        })
        .catch((error) => {
            console.log('error from fetch',error);
            callback(null,error);
    });
    
} 

function getEndPoint(ep){

    var SERVER_URL = getBaseEndPoint() + "/api";

    return SERVER_URL + ep;
} export {getEndPoint}

function getBaseEndPoint(){
    // staging
    // public static final String SERVER_URL = "https://knowlabel-staging.herokuapp.com/workers/api/v1/";

    // local
    //var SERVER_URL = "http://192.168.146.1:3000/workers/api/v1/";

    // local2
    //var SERVER_URL = "http://10.0.2.2:3000/workers/api/v1/";

    // local3
    //var SERVER_URL = "http://192.168.1.13:3000/workers/api/v1/";

    // themills
    //var SERVER_URL = "http://172.19.141.63:3000/workers/api/v1/";

    
    // Local Development

    //var SERVER_URL = "http://babizbay.dyndns.org:8080";

    // production

    //var SERVER_URL = "http://localhost:8080";

    
    var SERVER_URL = "https://www.lightpip.com";

    //var SERVER_URL = `${process.env.PUBLIC_URL}`;

    return SERVER_URL;

} export {getBaseEndPoint}
