import React, { useEffect, useState } from "react";
import ReactHlsPlayer from 'react-hls-player';
import { useTranslation } from 'react-i18next';

const HeaderTron = (props) => {
 
  const { t, i18n } = useTranslation();

  return (
    <div>
    <section class="py-0 text-center text-white" style={{position:'relative',overflow:'hidden', backgroundColor:props.color}}> 
          <ReactHlsPlayer
          src={props.video}
          autoPlay={true}
          controls={false}
          loop={true}
          width="auto"
          height="100%"
          style={{position:'absolute',top:0,left:0}}
        />
         <div class="mt-5 mr-1 text-right" style={{minHeight:'400px'}}>
                 <div class="row">
                     <div class="col-lg-8 col-md-10 mx-auto">
                     <h1 class="mb-4" style={{color : 'white'}}>{props.title}</h1>
                     <p class="lead mb-3">{props.desc}</p>
                     <a href="#contactnow" class="btn btn-lg btn-primary mx-1 mt-2">{t('contact_us')}</a>
                     </div>
                 </div>
         </div>
     </section>
    </div>
  );
};

export default HeaderTron;
