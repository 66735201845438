import React, { useEffect, useState } from "react";
import {apiCall} from '../service/ApiService';
import  EndPoint from '../util/EndPoint'; 
import { useTranslation } from 'react-i18next';




const Contact = () => {

  const [userName, setUserName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);


  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  const [bgImg, setBgImg]= useState({
    backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/Contact.png)`
  });

  const color= localStorage.getItem("color");

  useEffect(()=>{  
    if(color === "color-1")
     setBgImg( {
      backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/Contact.png)`
    });
    else if(color === "color-2")
    setBgImg({ backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-2/contact.png)`
	})
    else 
    setBgImg({backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-3/contact.png)`
	})
  },[color]);

  const onChange = (e) =>{
    const {name,value} = e.target
    console.log("e.name:",name);
    console.log("e.value:",value);
    if (name === 'userName'){
       setUserName(value)
    }
    if (name === 'phone'){
       setPhone(value)
    }

    if (name === 'email'){
       setEmail(value)
    }

    if (name === 'message'){
       setMessage(value)
    }
 }

 const addWebContactMsg = () =>{
    const params = {
        userName      : userName,
        phone         : phone,
        email         : email,
        message       : message,
    }
    console.log(params)
    apiCall(EndPoint.EP.ADDWEBCONTACTMESSAGE,params,(response,error)=>{
        if (error!=null){
            alert(t('operationfail'))
            return;
        }
        alert(t('contactus.confirmation'))
    }) 
 }

 const onSubmit = (event) =>{
    event.preventDefault();
    //alert('A name was submitted: ' + this.state.value);
    addWebContactMsg()
 }

 const { t, i18n } = useTranslation();

  return (
    <section id="contact" className="contact" style={bgImg}>
      <div className="contact-decor">
        <div className="contact-circle1">
          <img src="assets/images/main-banner12.png" alt="" />
        </div>
        <div className="contact-circle2">
          <img src="assets/images/main-banner1.png" alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8 col-sm-12">
            <div className="contact-us">
              <div className="w-100">
                <h2 className="title">
                  <span>{t('contactus.contact')} </span> {t('contactus.information')}
                </h2>
                <form className="theme-form" onSubmit={onSubmit}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <input
                          type="text"
                          name="userName"
                          className="form-control"
                          placeholder={t('contactus.yourname')}
                          required="required"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <input
                          name="phone"
                          type="text"
                          className="form-control"
                          placeholder={t('contactus.phone')}
                          required="required"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder={t('contactus.email')}
                      required="required"
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      placeholder={t('contactus.message')}
                      required="required"
                      onChange={onChange}
                    ></textarea>
                  </div>
                  <div className="form-button">
                    <button
                      type="submit"
                      className="btn btn-custom theme-color"
                    >
                      {t('contactus.send')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-medium-none-lg">
            <div className="contact-right">
              <img
                src="assets/images/Contact-info.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
