import React, { useEffect, useState } from "react";
import ContactNow from './ContactNow';
import InFooter from './InFooter';
import FeatureGraph from './FeatureGraph';
import HeaderTron from './HeaderTron';
import { useTranslation } from 'react-i18next';


const StampCard = () => {

  const { t, i18n } = useTranslation();
  
  return (
    <div>
       
        <HeaderTron color={'#FCE5D6'} video="https://videodelivery.net/60d6d476405f9dd4e47c1ae15d45b6a9/manifest/video.m3u8" title={t('stampcard.title')} desc="" />


        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#FFCAA8'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('stampcard.row1.col1.title')}</b> </h2>
                            <p class="lead text-white">{t('stampcard.row1.col1.desc')}</p>
                        </div>
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-2"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/a1f884a1-6092-415a-30dd-58f1dfeb8a00/public"} width="1500"/> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#FCE5D6'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-1"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/ecb00c92-012e-4d89-85b1-629090c27200/public"} width="1500"/> 
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('stampcard.row2.col1.title')}</b> </h2>
                            <p class="lead">{t('stampcard.row2.col1.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5" style={{backgroundColor : '#FFFFFF'}}>
            <div class="row">
                <div class="text-center mx-auto col-md-12">
                <h2 class="text-white mb-4 text-right" >{t('stafflikeit')}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 p-3" >
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/003e4089-5bd2-45db-4ab8-15cd59c01f00/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('stampcard.row3.col1.title')}</h3>
                        <p class="mt-3 mb-0">{t('stampcard.row3.col1.desc')}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/f863b7c4-3bd3-4be8-c50f-f0a567d04f00/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('stampcard.row3.col2.title')}</h3>
                        <p class="mt-3 mb-0">{t('stampcard.row3.col2.desc')}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 p-3">
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/d76ed5d9-c2ce-4ac6-dd8b-5d5dab1ab600/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('stampcard.row3.col3.title')}</h3>
                        <p class="mt-3 mb-0">{t('stampcard.row3.col3.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="text-center mx-auto col-md-12">
                <h2 class="text-white mt-4 text-left" style={{fontSize : 20}}>{t('customerloveit')}</h2>
                </div>
            </div>
        </section>
       
        <FeatureGraph image="https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/de5efb9f-7921-4ed5-c704-cf6e8099f800/public" />

        <ContactNow bcolor="#FCE5D6" fcolor="#FCC4A1"/>

        <InFooter color="#FFCAA8"/>


    </div>
  );
};

export default StampCard;
