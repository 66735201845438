import React, { useEffect,useState } from "react";
import {apiCall} from '../service/ApiService';
import  EndPoint from '../util/EndPoint'; 
import { useTranslation } from 'react-i18next';

const Subscribe = () => {

const [email, setEmail] = useState(null);

  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  const onChange = (e) =>{
    const {name,value} = e.target
    console.log("e.name:",name);
    console.log("e.value:",value);
    if (name === 'email'){
        setEmail(value)
    }
 }

 const addNewsLetter = () =>{
    const params = {
        email         : email,
    }
    console.log(params)
    apiCall(EndPoint.EP.ADDNEWSLETTER,params,(response,error)=>{
        if (error!=null){
            alert('Operation Failed - Invalid User Name, Email or Password')
            return;
        }
        alert('Thank you for subscribing to our Newsletter.')
    }) 
 }

 const onSubmit = (event) =>{
    event.preventDefault();
    addNewsLetter()
 }

 const { t, i18n } = useTranslation();

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="footer-text">
              <img src={`${process.env.PUBLIC_URL}/assets/images/email.png`} alt="email" />
              <h2 className="title text-center md-margin-top">
                {t('subscribe.subscribeto')} <span>{t('subscribe.newsletter')}</span>
              </h2>
              <p>{t('subscribe.description')}</p>
              <form className="theme-form"  onSubmit={onSubmit}>
                <div className="form-group">
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder={t('subscribe.email')}
                    id="mce-EMAIL"
                    required="required"
                    onChange={onChange}
                  />
                </div>
                <div className="form-button">
                  <button
                    type="submit"
                    className="btn btn-custom theme-color"
                    id="mc-submit"
                  >
                    {t('subscribe.send')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
