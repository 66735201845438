function createSession(userId, token, ttl) {
    localStorage.removeItem('mysession')
    const now = new Date()
    const item = {
        userId: userId,
        token: token,
        ttl : ttl,
        expiry: now.getTime() + ttl
    }
    localStorage.setItem('mysession', JSON.stringify(item))
} export {createSession};


function getSession() {
    const itemStr = localStorage.getItem('mysession')
    console.log("getsession:",itemStr)
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	if (now.getTime() > item.expiry) {
		localStorage.removeItem('mysession')
		return null
    }
    item.expiry = now.getTime() + item.ttl;
    localStorage.setItem('mysession', JSON.stringify(item))
    const output = {
        userId : item.userId,
        token : item.token,
    }
	return output
} export {getSession};