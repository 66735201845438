import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  const { t, i18n } = useTranslation();  

  return (
    <div>
      <section className="p-0">
        <div className="container-fluid">
          <div className="bottom-section">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="address-bar">
                  <div>
                    <ul className="footer-style">
                        <li>
                            <div className="footer-address">
                                <a href={null}>LIGHTPIP LIMITED</a>
                            </div>
                        </li>
                        <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/locate.png`} alt="locate" />
                        </div>
                        <div className="footer-address">
                            <a href={null}>{t('footer.address')}</a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/telephone.png`} alt="telephone" />
                        </div>
                        <div className="footer-address">
                            <a href={null}>+852 39068520</a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/fotter-email.png`} alt="fotter-email" />
                        </div>
                        <div className="footer-address">
                            <a href={null}>support@lightpip.com</a>
                        </div>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-0">
                <iframe  id="gmap_canvas" 
                src="https://maps.google.com/maps?q=8%20Wang%20Kwong%20Road,%20Kowloon%20Bay,%20Hong%20Kong+(LightPip)&t=&z=13&ie=UTF8&iwloc=&output=embed"                        
                frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                </iframe>
               </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright-section index-footer">
        <p>{t('footer.copyright')} &copy; LightPip Limited</p>
      </div>

      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up"></i>
        </div>
      </div>
      <div id="fb-root"></div>
      {/*Your customer chat code*/}
      <div
        className="fb-customerchat"
        page_id="2123438804574660"
        theme_color="#18e7d3"
        logged_in_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
        logged_out_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
      ></div>
    </div>
  );
};

export default Footer;
