import React, { useEffect, useState } from "react";
import Menu from "./Navbar";
import Tilt from "react-tilt";
import About from "./About";
import Feature from "./Feature";
import ScreenShot from "./ScreenShot";
import Team from "../Team";
import Blog from "../Blog";
import Price from "../Price";
import Contact from "./Contact";
import Subscribe from "./Subscribe";
import Footer from './Footer';
import Testimonial from "./Testimonial";

import tawkTo from "tawkto-react";
import { useTranslation } from 'react-i18next';

const MainApp = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  /*For changing background image by changing the color*/
  const [bgImg, setBgImg]= useState({
    backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/1.png)`
  });
  const color= localStorage.getItem("color");

  useEffect(()=>{
    if(color === "color-1")
     setBgImg( {
      backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/1.png)`
    });
    else if(color === "color-2")
    setBgImg({ backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-2/1.png)`
	})
    else 
    setBgImg({backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-3/1.png)`
	})
  },[color]);
  
  document.body.classList.remove("landing-page");
  document.body.classList.remove("home-style");
  document.body.classList.remove("three");
  document.body.classList.remove("home-style-two");

    let ios = "https://apps.apple.com/app/id1555013454"
    let android = "https://play.google.com/store/apps/details?id=com.lightpip.mobiapp"

  const tawkToPropertyId = '6196640c6bb0760a49433a5f';
  const tawkToKey = '1fkpndre4';

  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey)
  }, [])

  const { t, i18n } = useTranslation();

  return (
    <div>
      {/* Navbar Component*/}
      <Menu homePage="home-one"/>
      {/* Home One Section Start */}
      <section id="home" className="home" style={bgImg}>
        <div className="home-decor">
          <div className="home-circle1">
            <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner3.png`} alt="" />
          </div>
          <div className="home-circle2">
            <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner12.png`} alt="" />
          </div>
          <div className="home-circle3">
            <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`} alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="home-contain">
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/logoimage.png`} alt="caption-img" className="caption-img" />
                    <h1> 
                        <span className="f-bold">{t('appname')}</span> 
                        <span className="f-bold f-color"> {t('app')}</span>
				            </h1>
                    <p align="left">
                        <span style={{color:'#19D1DD', fontSize:24}}>{t('mainapp.slogon1')}</span><span>{t('mainapp.slogon2')}</span>
                    </p>
                    <p align="left">
                      {t('mainapp.slogon3')}
                    </p>
                    {/*
                    <a href="javascript" onClick={(e)=>{e.preventDefault()}}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`} alt="appstore" className="store" />
                    </a>
                    <a href="javascript" onClick={(e)=>{e.preventDefault()}}>
                        <img
                        className="ml-10 store"
                        src={`${process.env.PUBLIC_URL}/assets/images/play-store.png`}
                        alt="play-store"
                        />
                    </a>
                    */}
                    <a href={ios}>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`} alt="appstore" className="store" />
                    </a>
                    <a href={android}>
                        <img
                        className="ml-10 store"
                        src={`${process.env.PUBLIC_URL}/assets/images/play-store.png`}
                        alt="play-store"
                        />
                    </a>
                    <div>
                      <p className="mt-3">{t('mainapp.slogon4')}</p>
                      <a href={`${process.env.PUBLIC_URL}/video`}>
                          <img src={`${process.env.PUBLIC_URL}/assets/images/videoimg.png`} style={{height:100}} alt="Video" />
                      </a>
                    </div>
                </div>
               
              </div>
            </div>
            <div className="col-sm-6 offset-md-1">
              <div className="home-right">
                <Tilt
                  options={{
                    perspective: 110,
                    speed: 400,
                    max: 1.2,
                    scale: 1,
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/assets/images/mobile.png`} className="img-fluid" alt="mobile" />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home One Section End */}
      {/* About Component*/}
      <About />
      {/*Feature Component*/}
      <Feature />
      {/*ScreenShot Component*/}
      <ScreenShot />
      {/*Team Component*/}
      {/*<Team />*/}
      {/*Blog Component*/}
      {/*<Blog />*/}
      {/*Price Component*/}
      {/*<Price />*/}
      {/*Testimonial Component*/}
      <Testimonial />
      {/*Contact Component*/}
      <Contact />
      {/*Subscription Component*/}
      <Subscribe />
      {/*Footer Component*/}
      <Footer />
    </div>
  );
};

export default MainApp;
