export default class EndPoint{
    static EP = {
        CHANGEPWDWEB                : "/auth/changepwdweb",
        AUTHAGENT                   : "/auth/authagent",
        REGISTERAGENT               : "/auth/registeragent",
        GETAGENTBYID                : "/services/getagentbyid",
        UPDATEAGENTPROFILE          : "/services/updateagentprofile",
        SIGNAGREEMENT               : "/services/signagreement",
        GETSUBSCRIPTIONAGENT        : "/services/getsubscriptionagent",
        GETCOMMISSIONAGENT          : "/services/getcommissionagent",
        UPLOADFILE                  : "/file/upload", 
        GETAGENTDOCUMENTS           : "/services/getagentdocuments",
        ADDDOCUMENT                 : "/services/adddocument",
        CHANGEPWDAGENT              : "/services/changepwdagent",
        FORGETPASSWORDAGENT         : "/services/forgetpasswordagent",
        CHANGEFORGETPWDAGENT        : "/services/changeforgetpwdagent",
        AUTHACCOUNT                 : "/auth/authaccount",
        GETUSERACCOUNT              : "/services/getuseraccount",
        GETSHOPSUBSCRIPTIONS        : "/services/getshopsubscriptions",
        GETSHOPSUBPAYMENTS          : "/services/getshopsubpayments",
        GETAVAILABLEUSAGE           : "/services/getavailableusage",
        ADDWEBCONTACTMESSAGE        : "/auth/addwebcontactmessage",
        ADDNEWSLETTER               : "/auth/addnewsletter",
        ADDCAMPAIGNCONTACT          : "/auth/addcampaigncontact",
    }
} 