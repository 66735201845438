import React, { useEffect, useState } from "react";
import ContactNow from './ContactNow';
import InFooter from './InFooter';
import FeatureGraph from './FeatureGraph';
import HeaderTron from './HeaderTron';

import { useTranslation } from 'react-i18next';


const MembershipSystem = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
       
        <HeaderTron color={'#FFECA8'} video="https://videodelivery.net/dd13e83f84b899a53fa2f17528182a71/manifest/video.m3u8" 
        title={t('membershipsystem.title')} desc="" />


        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#FFE687'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('membershipsystem.row1.col1.title')}</b> </h2>
                            <p class="lead">{t('membershipsystem.row1.col1.desc')}</p>
                        </div>
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-2"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/2352714b-0f0c-4508-e998-0a632f25e900/public"} width="1500"/> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#F7F2E2'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-1"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/f141cea0-db4a-4ff3-e18b-3a7721bc6a00/public"} width="1500"/> 
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('membershipsystem.row2.col1.title')}</b> </h2>
                            <p class="lead">{t('membershipsystem.row2.col1.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5" style={{backgroundColor : '#FFFFFF'}}>
            
            <div class="row">
                <div class="col-lg-6 col-md-6 p-5">
                    <div class="card h-100">
                        <div class="card-body p-5">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/8e45a5a6-5746-4f80-71ac-3934aafd1200/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('membershipsystem.row3.col1.title')}</h3>
                            <p class="mt-3 mb-0">{t('membershipsystem.row3.col1.desc')}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 p-5">
                    <div class="card h-100" style={{backgroundColor : '#FFF8D3'}}>
                        <div class="card-body p-5">
                        <div class="row">
                           
                        </div>
                        <h2 class="mt-3 mb-0">{t('membershipsystem.row3.col2.title')}</h2>
                        <p class="mt-3 mb-0">{t('membershipsystem.row3.col2.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#D6A956'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('membershipsystem.row4.col1.title')}</b> </h2>
                            <p class="lead">{t('membershipsystem.row4.col1.desc')}</p>
                        </div>
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-2"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/c02a7878-a88d-4262-611b-27ee9f445f00/public"} width="1500"/> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <FeatureGraph image="https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/4dae24b6-50d6-4a31-e9c4-d3a232164900/public" />

        <ContactNow bcolor="#FDFBF1" fcolor="#FFE274"/>

        <InFooter color="#FFECA8"/>


    </div>
  );
};

export default MembershipSystem;
