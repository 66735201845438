import React, { useEffect, useState } from "react";
import ContactNow from './ContactNow';
import InFooter from './InFooter';
import FeatureGraph from './FeatureGraph';
import HeaderTron from './HeaderTron';
import { useTranslation } from 'react-i18next';

const WebIntegration = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
       
        <HeaderTron color={'#9EE5ED'} video="https://videodelivery.net/61f6a57c0e98526afda6d171fe0daddc/manifest/video.m3u8" 
        title={t('integration.title')} desc={t('integration.description')} />

        <section class="container-fluid py-5 px-5" style={{backgroundColor : '#E7F4F5'}}>
            <div class="row">
                <div class="text-center mx-auto col-md-12">
                <h2 class="text-white mb-4 text-right" >{t('stafflikeit')}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 p-3" >
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/4a5b038f-6051-4609-b144-d3874fb47700/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('integration.row1.col1.title')}</h3>
                        <p class="mt-3 mb-0">{t('integration.row1.col1.desc')}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/4c6a3e2d-1b9a-4737-8e6b-ad7e6bd3e800/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('integration.row1.col2.title')}</h3>
                        <p class="mt-3 mb-0">{t('integration.row1.col2.desc')}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 p-3">
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/0df9dc24-a564-4aaa-7c17-1cdb05c48f00/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('integration.row1.col3.title')}</h3>
                        <p class="mt-3 mb-0">{t('integration.row1.col3.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="text-center mx-auto col-md-12">
                <h2 class="text-white mt-4 text-left" style={{fontSize : 20}}>{t('customerloveit')}</h2>
                </div>
            </div>
        </section>

        <FeatureGraph image="https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/fbdbd415-7971-491b-6c60-df65306c0000/public" />

        <ContactNow bcolor="#FFFFFF" fcolor="#A9E8EF"/>

        <InFooter color="#9EE5ED"/>


    </div>
  );
};

export default WebIntegration;
