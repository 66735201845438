import React, { useEffect, useState } from "react";
import ContactNow from './ContactNow';
import InFooter from './InFooter';
import FeatureGraph from './FeatureGraph';
import HeaderTron from './HeaderTron';
import { useTranslation } from 'react-i18next';


const AestheticDesign = () => {
  const { t, i18n } = useTranslation();
 
  return (
    <div>
       
        <HeaderTron color={'#F0EFFE'} video="https://videodelivery.net/bf4ed56497eb8cd5a29ed76e631f9658/manifest/video.m3u8"
                         title={t('aestheticdesign.title')} desc={t('aestheticdesign.description')}/>

        <section class="container-fluid py-5 px-5" style={{backgroundColor : '#9794B7'}}>
            <div class="row">
                <div class="text-center mx-auto col-md-12">
                <h2 class="text-white mb-4 text-right" >{t('stafflikeit')}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 p-3" >
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/3ddec665-46e7-4ab9-08f5-2bb3c3c6d400/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('aestheticdesign.row1.col1.title')}</h3>
                        <p class="mt-3 mb-0">{t('aestheticdesign.row1.col1.desc')}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/5914f183-6d42-42fe-f5a1-9091d7cb6000/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('aestheticdesign.row1.col2.title')}</h3>
                        <p class="mt-3 mb-0">{t('aestheticdesign.row1.col2.desc')}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 p-3">
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/b973d25e-836a-4bc2-e0c6-7fe20ecd4a00/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('aestheticdesign.row1.col3.title')}</h3>
                        <p class="mt-3 mb-0">{t('aestheticdesign.row1.col3.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="text-center mx-auto col-md-12">
                <h2 class="text-white mt-4 text-left" style={{fontSize : 20}}>{t('customerloveit')}</h2>
                </div>
            </div>
        </section>

        <FeatureGraph image="https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/78583d2a-5a99-429b-d82a-57f80f32f800/public" />

        <ContactNow bcolor="#FFFFFF" fcolor="#9794B7"/>

        <InFooter color="#F0EFFE"/>


    </div>
  );
};

export default AestheticDesign;
