import "./App.css";
import BlogDetails from "./blog/BlogDetails";
import BlogLeftSide from "./blog/BlogLeftSide";
import BlogLeftSidebar from "./blog/BlogLeftSidebar";
import BlogList from "./blog/BlogList";
import BlogRightSide from "./blog/BlogRightSide";
import BlogRightSidebar from "./blog/BlogRightSidebar";
import DemoApp from "./demo-page/index";
import PageNotFound from "./Pages/404";
import ComingSoon from "./Pages/ComingSoon";
import Download from "./Pages/Download";
import FAQ from "./Pages/FAQ";
import ForgetPwd from "./Pages/ForgetPwd";
import Review from "./Pages/Review";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
//import ThankYou from "./Pages/ThankYou";
import React,{useEffect,Suspense} from "react";
import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import { Route, Switch } from "react-router-dom";
import ColorPicker from "./Services/ColorPicker";

import MainApp from './components/core/MainApp';
import AppHelp from './components/core/AppHelp';
import TermsConditions from './components/core/TermsConditions';
import SimpleSetup from './components/inner/SimpleSetup';
import AestheticDesign from './components/inner/AestheticDesign';
import MultiChannel from './components/inner/MultiChannel';
import WebIntegration from './components/inner/WebIntegration';
import MembershipSystem from "./components/inner/MembershipSystem";
import OnlineMarketing from "./components/inner/OnlineMarketing";
import Coupon from "./components/inner/Coupon";
import StampCard from "./components/inner/StampCard";
import DataAnalysis from "./components/inner/DataAnalysis";
import VoiceOver from "./components/inner/VoiceOver";
import Video from "./components/core/Video";
import CampaignAlpha from "./components/marketing/CampaignAlpha";
import ThankYou from "./components/marketing/ThankYou"


import MainApp_Zh from "./components/core/MainApp_Zh";


function App() {
  useEffect(() => {

    console.log("App Start ********************")

    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
  localStorage.setItem("color","color-1");

  
  return (

    <Suspense fallback="loading">
    <div className="App">
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/en`} component={MainApp} />

        <Route exact path={`${process.env.PUBLIC_URL}/`} component={MainApp} />

        <Route
          path={`${process.env.PUBLIC_URL}/apphelp/:lang/:topic`}
          render={(props) => <AppHelp {...props} />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/termsconditions`}
          component={TermsConditions}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/simplesetup`}
        >
          <SimpleSetup />  
        </Route>

        <Route
          path={`${process.env.PUBLIC_URL}/en/simplesetup`}
        >
          <SimpleSetup />  
        </Route>

        <Route
          path={`${process.env.PUBLIC_URL}/aestheticdesign`}
          component={AestheticDesign}
        />
         <Route
          path={`${process.env.PUBLIC_URL}/multichannel`}
          component={MultiChannel}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/webintegration`}
          component={WebIntegration}
        />
         <Route
          path={`${process.env.PUBLIC_URL}/membershipsystem`}
          component={MembershipSystem}
        />
         <Route
          path={`${process.env.PUBLIC_URL}/onlinemarketing`}
          component={OnlineMarketing}
        />
         <Route
          path={`${process.env.PUBLIC_URL}/coupon`}
          component={Coupon}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/stampcard`}
          component={StampCard}
        />  
        <Route
          path={`${process.env.PUBLIC_URL}/dataanalysis`}
          component={DataAnalysis}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/voiceover`}
          component={VoiceOver}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/video`}
          component={Video}
        />

        <Route
          path={`${process.env.PUBLIC_URL}/campaignalpha`}
          component={CampaignAlpha}
        />

        <Route
          path={`${process.env.PUBLIC_URL}/thankyou`}
          component={ThankYou}
        />

        <Route
          path={`${process.env.PUBLIC_URL}/home-one`}
          component={HomeOne}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-two`}
          component={HomeTwo}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-three`}
          component={HomeThree}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-details`}
          component={BlogDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-list`}
          component={BlogList}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-left-sidebar`}
          component={BlogLeftSidebar}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-right-sidebar`}
          component={BlogRightSidebar}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-leftside`}
          component={BlogLeftSide}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-rightside`}
          component={BlogRightSide}
        />
        <Route path={`${process.env.PUBLIC_URL}/sign-in`} component={SignIn} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/sign-up`}
          component={SignUp}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/forget-password`}
          component={ForgetPwd}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/thank-you`}
          component={ThankYou}
        />
        <Route path={`${process.env.PUBLIC_URL}/review`} component={Review} />
        <Route
          path={`${process.env.PUBLIC_URL}/404`}
          component={PageNotFound}
        />
        <Route path={`${process.env.PUBLIC_URL}/faq`} component={FAQ} />
        <Route
          path={`${process.env.PUBLIC_URL}/download`}
          component={Download}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/coming-soon`}
          component={ComingSoon}
        />
        <Route component={PageNotFound} />
      </Switch>
      
    </div>
    </Suspense>

  );
}

export default App;
