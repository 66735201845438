import React, { useEffect, useState } from "react";
import {apiCall} from '../service/ApiService';
import  EndPoint from '../util/EndPoint'; 
import { useTranslation } from 'react-i18next';

const ContactNow = (props) => {

  const [userName, setUserName] = useState(null);
  const [company, setCompany] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);


  const onChange = (e) =>{
    const {name,value} = e.target
    console.log("e.name:",name);
    console.log("e.value:",value);
    if (name === 'userName'){
       setUserName(value)
    }
    if (name === 'company'){
        setCompany(value)
     }
    if (name === 'phone'){
       setPhone(value)
    }

    if (name === 'email'){
       setEmail(value)
    }

    if (name === 'message'){
       setMessage(value)
    }
 }

 const addWebContactMsg = () =>{
    const params = {
        userName      : userName,
        company       : company,
        phone         : phone,
        email         : email,
        message       : message,
    }
    console.log(params)
    apiCall(EndPoint.EP.ADDWEBCONTACTMESSAGE,params,(response,error)=>{
        if (error!=null){
            alert('Operation Failed')
            return;
        }
        alert('We have received your message. Our representative will contact you shortly.')
    }) 
 }

 const onSubmit = (event) =>{
    event.preventDefault();
    //alert('A name was submitted: ' + this.state.value);
    addWebContactMsg()
 }

  const { t, i18n } = useTranslation();
 
  return (
    <div>
        <section id="contactnow" class="py-5" style={{backgroundColor : props.bcolor}}>
            <div class="container">
            <div class="row">
                <div class="col-lg-6 order-2 order-lg-1 p-5"> 
                    <h1 style={{fontSize : 30, textAlign :'center'}}>{t('contactnow.name')}</h1>
                    <img class="img-fluid d-block" src= {"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/7816cb50-014d-44ee-870b-910bbedbe400/public"} /> 
                </div>
                <div class="px-5 col-lg-6 d-flex flex-column align-items-start justify-content-center order-1 order-lg-2">
                    <form class="text-left py-3 px-5" style={{backgroundColor : props.fcolor, borderRadius : 10}} onSubmit={onSubmit}>
                        <p class="mb-4">{t('contactnow.title')}</p>
                        <div class="form-group"> 
                            <label for="form16">{t('contactnow.form.yourname')}</label> 
                            <input type="text" class="form-control" id="form16" name="userName" placeholder="" required="required" onChange={onChange}/> 
                        </div>
                        <div class="form-group">
                            <label for="form17">{t('contactnow.form.company')}</label>
                            <input type="text" class="form-control" id="form17" name="company" placeholder="" onChange={onChange}/> 
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6"> 
                                <div class="form-group"> 
                                    <label for="form18">{t('contactnow.form.phone')}</label> 
                                    <input type="phone" class="form-control" id="form18" name="phone" placeholder="" required="required" onChange={onChange}/> 
                                </div>
                            </div>
                            <div class="form-group col-md-6"> 
                                <div class="form-group"> 
                                    <label for="form19">{t('contactnow.form.email')}</label> 
                                    <input type="email" class="form-control" id="form19" name="email" placeholder="" onChange={onChange}/> 
                                </div>
                            </div>
                        </div>
                        <div class="form-group"> 
                            <label for="form20">{t('contactnow.form.message')}</label> 
                            <textarea type="text" rows="4" class="form-control" id="form20" name="message" placeholder="" onChange={onChange}/> 
                        </div>
                        <button type="submit" class="btn btn-primary">{t('send')}</button>
                    </form>
                </div>
            </div>
            </div>
        </section>
    </div>
  );
};

export default ContactNow;
