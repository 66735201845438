import React, { useEffect, useState } from "react";
import ContactNow from './ContactNow';
import InFooter from './InFooter';
import FeatureGraph from './FeatureGraph';
import HeaderTron from './HeaderTron';
import { useTranslation } from 'react-i18next';

const OnlineMarketing = () => {

  const { t, i18n } = useTranslation();
  
  return (
    <div>
       
        <HeaderTron color={'#CDC8FB'} video="https://videodelivery.net/3fb304af72c53f547549c7ad1fd322b9/manifest/video.m3u8" title={t('onlinemarketing.title')} desc="" />


        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#7D7AA6'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('onlinemarketing.row1.col1.title')}</b> </h2>
                            <p class="lead text-white">{t('onlinemarketing.row1.col1.desc')}</p>
                        </div>
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-2"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/e0a5162b-90b9-4b64-9b92-9b79f5d3e900/public"} width="1500"/> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#F1F0FF'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-1"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/084d01c8-8106-43c1-f2bd-aed4774f4500/public"} width="1500"/> 
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('onlinemarketing.row2.col1.title')}</b> </h2>
                            <p class="lead">{t('onlinemarketing.row2.col1.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5" style={{backgroundColor : '#FFFFFF'}}>
            
            <div class="row">
                <div class="col-lg-6 col-md-6 p-5">
                    <div class="card h-100">
                        <div class="card-body p-5">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/8b0c2d7a-7ce1-4ac6-eb92-d486b56e9100/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('onlinemarketing.row3.col1.title')}</h3>
                            <p class="mt-3 mb-0">{t('onlinemarketing.row3.col1.desc')} 
                                                
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 p-5">
                    <div class="card " style={{backgroundColor : '#F0EFFE'}}>
                        <div class="card-body p-5">
                        <div class="row">
                           
                        </div>
                        <h2 class="mt-3 mb-0">{t('onlinemarketing.row3.col2.title')}</h2>
                        <p class="mt-3 mb-0">{t('onlinemarketing.row3.col2.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5" style={{backgroundColor : '#FFFFFF'}}>
            
            <div class="row">
                <div class="col-lg-6 col-md-6 p-5">
                    <div class="card h-100">
                        <div class="card-body p-5">
                        <div class="row">
                            <div class="col-md-12 "> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/821aa998-b7b7-419f-7d04-2e441edc8000/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0"></h3>
                            <p class="mt-3 mb-0">
                                                
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 p-5">
                    <div class="card " style={{backgroundColor : '#F0EFFE'}}>
                        <div class="card-body p-5">
                        <div class="row">
                           
                        </div>
                        <h2 class="mt-3 mb-0">{t('onlinemarketing.row4.col1.title')}</h2>
                        <p class="mt-3 mb-0">{t('onlinemarketing.row4.col1.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#F0EFFE'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('onlinemarketing.row5.col1.title')}</b> </h2>
                            <p class="lead">{t('onlinemarketing.row5.col1.desc')}</p>
                        </div>
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-2"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/2173243d-737e-43b6-eed4-e87d0e2af800/public"} width="1500"/> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#FFFFFF'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-1"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/0a652d3d-bf7a-4b5e-f4fe-71208da45800/public"} width="1500"/> 
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('onlinemarketing.row6.col1.title')}</b> </h2>
                            <p class="lead">{t('onlinemarketing.row6.col1.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        
        <FeatureGraph image="https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/3b324e3a-a2ed-4fb7-b17c-983bd9dca700/public" />

        <ContactNow bcolor="#F0EFFE" fcolor="#9694B7"/>

        <InFooter color="#CDC8FB"/>


    </div>
  );
};

export default OnlineMarketing;
