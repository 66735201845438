import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const Feature = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
  const [bgImg, setBgImg]= useState({
    backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`
  });

  const color= localStorage.getItem("color");
  useEffect(()=>{
   
    if(color === "color-1")
     setBgImg( {
      backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`
    });
    else if(color === "color-2")
    setBgImg({ backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-2/feature-bg1.png)`})
    else 
    setBgImg({backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-3/feature-bg1.png)`})
  },[color]);  

  const { t, i18n } = useTranslation();

  return (
    <section id="feature" className="feature" style={bgImg}>
      <div className="feature-decor">
        <div className="feature-circle1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="feature-phone">
            <img src={`${process.env.PUBLIC_URL}/assets/images/222.png`} className="img-fluid" alt="" />
          </div>
          <div className="offset-lg-4 col-lg-8">
            <div className="row">
              <div className="col-sm-12 mrgn-md-top">
                <h2 className="title">
                {t('featureapp.featureof')}<span> {t('appname')}</span>
                </h2>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/1.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <a href={`${process.env.PUBLIC_URL}/membershipsystem`}>
                            <h3 style={{color:'blue'}}>{t('featureapp.feature1')}</h3>
                      </a>
                    </div>
                    <div>
                        <p>
                          {t('featureapp.content1')}
                        </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <a href={`${process.env.PUBLIC_URL}/coupon`}>
                            <h3 style={{color:'blue'}}>{t('featureapp.feature2')}</h3>
                      </a>
                    </div>
                    <div>
                        <p>
                          {t('featureapp.content2')}
                        </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/5.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <a href={`${process.env.PUBLIC_URL}/stampcard`}>
                            <h3 style={{color:'blue'}}>{t('featureapp.feature3')}</h3>
                      </a>
                    </div>
                    <div>
                        <p>{t('featureapp.content3')}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6 sm-m-top">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/2.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <a href={`${process.env.PUBLIC_URL}/onlinemarketing`}>
                            <h3 style={{color:'blue'}}>{t('featureapp.feature4')}</h3>
                      </a>
                    </div>
                    <div>
                        <p>{t('featureapp.content4')}</p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <a href={`${process.env.PUBLIC_URL}/dataanalysis`}>
                            <h3 style={{color:'blue'}}>{t('featureapp.feature5')}</h3>
                      </a>
                    </div>
                    <div>
                        <p>
                        {t('featureapp.content5')}
                        </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                        <a href={`${process.env.PUBLIC_URL}/voiceover`}>
                            <h3 style={{color:'blue'}}>{t('featureapp.feature6')}</h3>
                        </a>
                    </div>
                    <div>
                        <p>{t('featureapp.content6')}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
