import React, { useEffect, useState } from "react";
import ContactNow from './ContactNow';
import InFooter from './InFooter';
import FeatureGraph from './FeatureGraph';
import HeaderTron from './HeaderTron';
import { useTranslation } from 'react-i18next';

const VoiceOver = () => {
  
  const { t, i18n } = useTranslation();

  return (
    <div>
       
        <HeaderTron color={'#A7D19A'} video="https://videodelivery.net/1eedb55b8584c8df822710d0227e5557/manifest/video.m3u8" title={t('voiceover.title')} desc="" />


        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#92C682'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('voiceover.row1.col1.title')}</b> </h2>
                            <p class="lead text-white">{t('voiceover.row1.col1.desc')}</p>
                        </div>
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-2"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/3b018dd9-a130-4f74-6686-65a03c22f000/public"} width="1500"/> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#E8F3E4'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-1"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/a78368f2-0008-4898-d190-e71da7611800/public"} width="1500"/> 
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('voiceover.row2.col1.title')}</b> </h2>
                            <p class="lead">{t('voiceover.row2.col1.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5" style={{backgroundColor : '#FFFFFF'}}>
            
            <div class="row">
                <div class="col-lg-6 col-md-6 p-5">
                    <div class="card h-100">
                        <div class="card-body p-5">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/5d678f3b-2675-4cb9-0ba0-c0c487206500/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('voiceover.row3.col1.title')}</h3>
                            <p class="mt-3 mb-0">{t('voiceover.row3.col1.desc')}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 p-5">
                    <div class="card " style={{backgroundColor : '#E8F3E4'}}>
                        <div class="card-body p-5">
                        <div class="row">
                           
                        </div>
                        <h2 class="mt-3 mb-0">{t('voiceover.row3.col2.title')}</h2>
                        <p class="mt-3 mb-0">{t('voiceover.row3.col2.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5" style={{backgroundColor : '#FFFFFF'}}>
            
            <div class="row">
                <div class="col-lg-6 col-md-6 p-5">
                    <div class="card h-100">
                        <div class="card-body p-5">
                        <div class="row">
                            <div class="col-md-12 "> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/a873c066-8f47-4d10-5e6b-0d1a8854a600/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('voiceover.row4.col1.title')}</h3>
                            <p class="mt-3 mb-0">
                            {t('voiceover.row4.col1.desc')}            
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 p-5">
                    <div class="card " style={{backgroundColor : '#92C682'}}>
                        <div class="card-body p-5">
                        <div class="row">
                           
                        </div>
                        <h2 class="mt-3 mb-0">{t('voiceover.row4.col2.title')}</h2>
                        <p class="mt-3 mb-0">{t('voiceover.row4.col2.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        
        <FeatureGraph image="https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/3d651887-252a-42af-d6f1-0af900bd1a00/public" />

        <ContactNow bcolor="#E8F3E4" fcolor="#A7D19A"/>

        <InFooter color="#A7D19A"/>


    </div>
  );
};

export default VoiceOver;
