import React, { useEffect, useState } from "react";


const FeatureGraph = (props) => {
 
  return (
    <div>
       <section class="container-fluid p-0">
            <div class="row">
                <div class="p-0 col-12"> 
                    <img class="img-fluid d-block" src= {props.image}/> 
                </div>
            </div>
        </section>
    </div>
  );
};

export default FeatureGraph;
