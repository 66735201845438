import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import {apiCall} from '../service/ApiService';
import  EndPoint from '../util/EndPoint'; 
import Select from 'react-select';

import { useHistory } from 'react-router-dom';

import ReactPixel from 'react-facebook-pixel';

const options = [
    { value: '店東', label: '店東' },
    { value: '職員', label: '職員' },
];

const CampaignAlpha = (props) => {
  
  const { t, i18n } = useTranslation();
 

  const [userName, setUserName] = useState(null);
  const [company, setCompany] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [position, setPosition] = useState("店東");
  const [address, setAddress] = useState(null);

  const history = useHistory();

  const onChange = (e) =>{
    const {name,value} = e.target
    console.log("e.name:",name);
    console.log("e.value:",value);
    if (name === 'userName'){
       setUserName(value)
    }
    if (name === 'company'){
        setCompany(value)
     }
    if (name === 'phone'){
       setPhone(value)
    }

    if (name === 'email'){
       setEmail(value)
    }

    if (name === 'position'){
       setPosition(value)
    }
    if (name === 'address'){
        setAddress(value)
     }
 }

 const addCompaignContact = () =>{
    const params = {
        campaignName  : "ShamShuiPo-1000",
        userName      : userName,
        position      : position,
        phone         : phone,
        email         : email,
        company       : company,
        address       : address  
    }
    console.log(params)
    apiCall(EndPoint.EP.ADDCAMPAIGNCONTACT,params,(response,error)=>{
        if (error!=null){
            alert('Operation Failed')
            return;
        }
        //alert('We have received your message. Our representative will contact you shortly.')
        sendFBEvent();
        history.push("/thankyou");

    }) 
 }

 const sendFBEvent = () =>{
    ReactPixel.init('292720518916022', {}, { debug: true, autoConfig: true });
    ReactPixel.fbq('track', 'CompleteRegistration');
 }


 const onSubmit = (event) =>{
    event.preventDefault();
    //alert('A name was submitted: ' + this.state.value);
    addCompaignContact()
 }

 const handleSelect = (e) =>{
    console.log(e.value)
    setPosition(e.value)
 }

  return (
    <div style={{backgroundColor : '#C4E1E4'}}>
        <div className="pt-4" style={{display: 'flex',alignItems: 'center',justifyContent: 'center',backgroundColor : '#C4E1E4'}}>
            <h2> <a href={`${process.env.PUBLIC_URL}/`}>LightPip</a> 【深水埗商戶有福鳥】</h2>
        </div>
     
       
        <section class="container-fluid px-0 py-0" style={{backgroundColor : '#C4E1E4'}}>
            <div class="row no-gutters">
                <div class="col-md-12">
                    <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/3ac9801e-c528-4cb7-9495-38d85b989100/public"}/> 
                </div>
            </div>
        </section>

        <div className="px-4 py-4" style={{display: 'flex',alignItems: 'center',justifyContent: 'center',backgroundColor : '#E2F2F3'}}>
            <h1> <a href={`${process.env.PUBLIC_URL}/`}>LightPip</a></h1>
        </div>
        <div className="px-4 py-0" style={{display: 'flex',alignItems: 'center',justifyContent: 'center',backgroundColor : '#E2F2F3'}}>
            <h2> 【<span style={{color:'red'}}>堅派錢</span>俾你做生意】商戶著數 (先到先得!)</h2>
        </div>
        <div className="px-4 py-4" style={{display: 'flex',alignItems: 'center',justifyContent: 'center',backgroundColor : '#C4E1E4'}}>
            <h1 style={{color:'red'}}>頭30名</h1>
        </div>
        <div className="px-4 py-0" style={{display: 'flex',alignItems: 'center',justifyContent: 'center',backgroundColor : '#C4E1E4'}}>
            <h2>深水埗商戶進駐LightPip CRM & Marketing 免費平台，即送最高<span style={{color:'red'}}>HKD$1,000</span>俾你派Discount俾你啲客!</h2>
        </div>
        <div className="px-4" style={{display: 'flex',alignItems: 'center',justifyContent: 'center',backgroundColor : '#C4E1E4'}}>
            <h2><a href={`${process.env.PUBLIC_URL}/`}>LightPip</a> - 商戶 CRM & Marketing 免費手機APP</h2>
        </div>
        <div className="container pt-4" style={{backgroundColor : '#C4E1E4'}}>
            <div className="row">
            <div className="offset-lg-3 col-lg-8">
                <div className="row">
                <div className="col-12">
                    <h2 className="title">
                   <span>產品功能 亮籽</span> <span>免安裝費免月費</span>
                    </h2>
                </div>
                <div className="col-6">
                    <ul className="feature-style">                    
                    <li>
                        <div className="feature-icon">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/1.png`} alt="icon" />
                        </div>
                        <div className="feature-subtitle">
                        <a href={`${process.env.PUBLIC_URL}/membershipsystem`} target="view_window">
                                <h3 style={{color:'blue'}}>會員系統</h3>
                        </a>
                        </div>
                    </li>
                    <li>
                        <div className="feature-icon">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`} alt="icon" />
                        </div>
                        <div className="feature-subtitle">
                        <a href={`${process.env.PUBLIC_URL}/coupon`} target="view_window">
                                <h3 style={{color:'blue'}}>電子優惠券</h3>
                        </a>
                        </div>
                    </li>
                    <li>
                        <div className="feature-icon">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/5.png`} alt="icon" />
                        </div>
                        <div className="feature-subtitle">
                        <a href={`${process.env.PUBLIC_URL}/stampcard`} target="view_window">
                                <h3 style={{color:'blue'}}>電子印花卡</h3>
                        </a>
                        </div>
                    </li>
                    </ul>
                </div>
                <div className="col-6">
                    <ul className="feature-style">
                    <li>
                        <div className="feature-icon">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/2.png`} alt="icon" />
                        </div>
                        <div className="feature-subtitle">
                        <a href={`${process.env.PUBLIC_URL}/onlinemarketing`} target="view_window">
                                <h3 style={{color:'blue'}}>數碼營銷</h3>
                        </a>
                        </div>
                    </li>
                    <li>
                        <div className="feature-icon">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`} alt="icon" />
                        </div>
                        <div className="feature-subtitle">
                        <a href={`${process.env.PUBLIC_URL}/dataanalysis`} target="view_window">
                                <h3 style={{color:'blue'}}>數據分析</h3>
                        </a>
                        </div>
                    </li>
                    <li>
                        <div className="feature-icon">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`} alt="icon" />
                        </div>
                        <div className="feature-subtitle">
                            <a href={`${process.env.PUBLIC_URL}/voiceover`} target="view_window">
                                <h3 style={{color:'blue'}}>聲音導航</h3>
                            </a>
                        </div>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div className="px-0" style={{display: 'flex',alignItems: 'center',justifyContent: 'center',backgroundColor : '#9EE5ED'}}>
            <h1 style={{color:'red'}}>玩法</h1>
        </div>
        <div className="px-4" style={{display: 'flex',alignItems: 'center',justifyContent: 'center',backgroundColor : '#9EE5ED'}}>
            <h2>活動有效日期 (2021年12月21日至2022年1月20日)</h2>
        </div>
        <div className="px-4" style={{display: 'flex',alignItems: 'center',justifyContent: 'center',backgroundColor : '#9EE5ED'}}>
            <h2>通過以下簡單的步驟,贊助費垂手可得.</h2>
        </div>
        <div className="px-0" style={{display: 'flex',alignItems: 'center',justifyContent: 'center',backgroundColor : '#9EE5ED'}}>
            <ul>
                <li>
                    <h3>1.  商戶填表登記</h3>
                </li>
                <li>
                    <h3>2.  裝APP</h3>
                </li>
                <li>
                    <h3>3.  LightPip 確認</h3>
                </li>
                <li>
                    <h3>4.  出優惠Post</h3>
                </li>
                <li>
                    <h3>5.  顧客用優惠券 | 儲印花</h3>
                </li>
                <li>
                    <h3>6.  商戶收贊助費 (活動完結後)</h3>
                </li>
            </ul>
        </div>


        <div class="container">
            <div class="row">
                <div class="px-5 col-12 ">
                    <form class="py-3 px-5" style={{backgroundColor : '#E2F2F3', borderRadius : 10}} onSubmit={onSubmit}>
                        <p class="mb-4">LightPip 【深水埗商戶有福鳥】宣傳活動登記表格</p>
                        <div class="form-group"> 
                            <label for="form16">你的名字</label> 
                            <input type="text" class="form-control" id="form16" name="userName" placeholder="" required="required" onChange={onChange}/> 
                        </div>
                        <div class="form-group"> 
                            <label for="form21">職位</label> 
                            <Select
                                onChange={handleSelect}
                                options={options}
                            />
                        </div>
                       
                        <div class="form-row">
                            <div class="form-group col-md-6"> 
                                <div class="form-group"> 
                                    <label for="form18">聯絡手機號碼</label> 
                                    <input type="phone" class="form-control" id="form18" name="phone" placeholder="" required="required" onChange={onChange}/> 
                                </div>
                            </div>
                            <div class="form-group col-md-6"> 
                                <div class="form-group"> 
                                    <label for="form19">電郵</label> 
                                    <input type="email" class="form-control" id="form19" name="email" placeholder="" onChange={onChange}/> 
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="form17">商店名稱</label>
                            <input type="text" class="form-control" id="form17" name="company" placeholder="" onChange={onChange}/> 
                        </div>

                        <div class="form-group"> 
                            <label for="form20">商店地址</label> 
                            <textarea type="text" rows="4" class="form-control" id="form20" name="address" placeholder="" onChange={onChange}/> 
                        </div>
                        <button type="submit" class="btn btn-primary">{t('send')}</button>
                    </form>
                </div>
            </div>
        </div>

        <section className="px-4" style={{marginTop:8, display: 'flex',alignItems: 'center',justifyContent: 'center',backgroundColor : '#C4E1E4'}}>
           
            <p>【LightPip 派錢比你做生意】 條款及細則 <br/>
            「參加商」指參加此計劃的商戶。 <br/>
            「顧客」指參加商戶的顧客。<br/>
            「頭30名」指頭30名該次活動指定區域，成功被LightPip 跟批核次序批核的商戶。批核的次序將會根據成功遞交申請的次序來處理。<br/>
            「指定區域」指商在商業登記上登記的地址，或在「指定區域」上有實體店。商戶是否被定義為坐落「指定區域」。「指定區域」將會根據每段時期的宣傳活動作準。<br/>
            領取 LightPip「HKD$900+HKD$100」宣傳贊助費的資格 <br/>
            頭30位登記及合資格商戶 <br/>
            商戶需於枇核後30天內建立LightPip 平台真實商戶賬號。 <br/>
            商戶需要在商戶賬號裡設置真實「優惠券」或/及 「印花」，以及在賬號裡對外公佈「優惠券」或/及 「印花」使用條款及細則。<br/>
            商戶需於實體店 (或網店) 當眼處展示最少1個印有下載 LightPip APP的二維碼立牌或海報。(立牌或海報將由LightPip 提供)<br/>
            LightPip 將會在2021年12月21至2022年1月20期間，顧客在APP裡使用「優惠券」或/及贖領儲滿 「印花」優惠後，根據商戶對外公佈的條款及細則，以現金方式將最高「HKD$900」，於本次宣傳活動完結後30天內轉賬到商戶的銀行賬戶裡。<br/>
            如在Facebook 甪到的活動宣傳廣告時留言「Sham Shui Po」額外獲得HKD$100贊助。(即共最高HKD$900 + 100)<br/>
            LightPip 對顧客每張已行使的「優惠券」，每個商戶的每個顧客，贊助最高不超過HKD$20。<br/>
            LightPip 對顧客每已行使的第一個「印花」儲滿贖領行為，贊助最多1張印花卡，最高不能超過HKD$50<br/>
            <br/>
            非「指定區域」，或「頭30名」後顧客亦可通過以下資格領取「HKD$400+100」優惠：<br/>
            商戶需於枇核後30天內建立LightPip 平台真實商戶賬號。 <br/>
            商戶需要在商戶賬號裡設置真實「優惠券」或/及 「印花」，以及在賬號裡對外公佈「優惠券」或/及 「印花」使用條款及細則。<br/>
            完成3個或以上Marketing宣傳posts（pip）。<br/>
            商戶需於實體店 (或網店) 當眼處展示最少1個印有下載 LightPip APP的二維碼立牌或海報。(立牌或海報將由LightPip 提供)<br/>
            LightPip 將會在2021年12月21至2022年1月20期間，顧客在APP裡使用「優惠券」或/及贖領儲滿 「印花」優惠後，根據商戶對外公佈的條款及細則，以現金方式將最高「HKD$400」，於達標後30天內轉賬到商戶的銀行賬戶裡。<br/>
            如在Facebook 甪到的活動宣傳廣告時留言「Sham Shui Po」額外獲得HKD$100贊助。(即共最高HKD$400 + 100)<br/>
            LightPip 對顧客每張已行使的「優惠券」，每個商戶的每個顧客，贊助最高不超過HKD$20。<br/>
            LightPip 對顧客每已行使的第一個「印花」儲滿贖領行為，贊助最多1張印花卡，最高不能超過HKD$50<br/>
            <br/>
            活動程序<br/>
            商戶在LightPip 網站裡登記。<br/>
            LightPip 確認商戶成為參加商資格，及確定最高可領取的宣傳贊助費。<br/>
            參加商開始根據指引在LightPip 裡設置脹號及出宣傳Post。<br/>
            LightPip將會提供立牌給參加商在店內展示。<br/>
            顧客成功使用優惠券或印花卡<br/>
            在宣傳活動期間完結後30天內，根據商戶的達標程度把現金轉賬到商戶提供的銀行賬號內。<br/>
            所有爭議，LightPip Limited擁有最終決定權。<br/>
            </p>
        </section>

        
    </div>
  );
};

export default CampaignAlpha;
