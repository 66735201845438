import React, { useEffect } from "react";



const TermsConditions = (props) => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  

  return (
    <div>
      {/*home section*/}
      <section className="authentication-form coming-soon" style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/aut-bg.jpg)`}}>
        <div className="innerpage-decor">
          <div className="innerpage-circle1">
            <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial2.png`} alt="" />
          </div>
          <div className="innerpage-circle2">
            <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial1.png`} alt="" />
          </div>
        </div>
      
        <div className="page-margin2">
            {/*breadcrumb start*/}
            <div className="breadcrumb-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                            <img style={{width:30,height:30,marginRight:10}} src='assets/images/logoimage.png'></img>
                            <h2 className="title"><span>Terms and Conditions 條款</span></h2>
                        </div>
                        <div className="col-md-6 col-sm-6 col-text-center">
                            <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                <ol className="breadcrumb bg-transparent mb-0">
                                    <li className="breadcrumb-item"><a href={`${process.env.PUBLIC_URL}/`}>Home</a></li>
                                    <li className="breadcrumb-item active"><a href="">Terms and Conditions</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/*breadcrumb end*/}

            {/*Review Section Start*/}
            <div style={{marginTop:20,marginBottom:20}}>
                        <div className="review-block">
                            <div className="container">
                                <div className="row margin-bottom">
                                    <div style={{marginLeft:10,marginRight:10}}>
                                    <p>
                                        By downloading or using the app, these terms will
                                        automatically apply to you – you should make sure therefore
                                        that you read them carefully before using the app. You’re not
                                        allowed to copy, or modify the app, any part of the app, or
                                        our trademarks in any way. You’re not allowed to attempt to
                                        extract the source code of the app, and you also shouldn’t try
                                        to translate the app into other languages, or make derivative
                                        versions. The app itself, and all the trade marks, copyright,
                                        database rights and other intellectual property rights related
                                        to it, still belong to Veefun Information Technology Limited.
                                        </p> 
                                        <p>
                                        通過下載或使用應用程序，這些條款將
                                        自動適用於您–因此，您應確保
                                        在使用該應用之前，請仔細閱讀它們。 你不是
                                        允許複製或修改應用程序，應用程序的任何部分，或
                                        我們商標的任何形式。 您無權嘗試
                                        提取應用程序的源代碼，也不要嘗試
                                        將應用翻譯成其他語言，或進行衍生
                                        版本。 該應用程序本身以及所有商標，版權，
                                        數據庫權利和其他與知識產權有關的權利
                                        到目前為止，它仍然屬於Veefun Information Technology Limited。
                                        </ p>
                                        <p>
                                        Veefun Information Technology Limited is committed to ensuring that the app is
                                        as useful and efficient as possible. For that reason, we
                                        reserve the right to make changes to the app or to charge for
                                        its services, at any time and for any reason. We will never
                                        charge you for the app or its services without making it very
                                        clear to you exactly what you’re paying for.
                                        </p> 
                                        <p>
                                        Veefun Information Technology Limited致力於確保該應用程序
                                        盡可能有用和高效。 因此，我們
                                        保留更改應用程序或收取費用的權利
                                        隨時隨地以任何理由提供其服務。 我們永遠不會
                                        向您收取該應用程序或其服務的費用，而無需使其變得非常
                                        向您明確說明您要支付的費用。
                                        </ p>
                                        <p>
                                        The LightPip app stores and processes personal data that
                                        you have provided to us, in order to provide our
                                        Service. It’s your responsibility to keep your phone and
                                        access to the app secure. We therefore recommend that you do
                                        not jailbreak or root your phone, which is the process of
                                        removing software restrictions and limitations imposed by the
                                        official operating system of your device. It could make your
                                        phone vulnerable to malware/viruses/malicious programs,
                                        compromise your phone’s security features and it could mean
                                        that the LightPip app won’t work properly or at all.
                                        </p> 
                                        <p>
                                        LightPip應用程序存儲和處理個人數據，
                                        您已提供給我們，以便提供我們的
                                        服務。 您有責任保留手機並
                                        安全訪問該應用。 因此，我們建議
                                        不要越獄或將手機紮根，這是
                                        消除軟件限制和限制
                                        官方操作系統。 它可以使你
                                        手機容易受到惡意軟件/病毒/惡意程序的攻擊，
                                        損害手機的安全功能，這可能意味著
                                        LightPip應用無法正常運行或完全無法運行。
                                        </ p>
                                        <div>
                                        <p>
                                            The app does use third party services that declare their own
                                            Terms and Conditions.
                                        </p> 
                                        <p>
                                            該應用程序確實使用了聲明自己的第三方服務
                                            條款和條件。
                                        </ p>
                                        <p>
                                            Link to Terms and Conditions of third party service
                                            providers used by the app
                                        </p> 
                                        <p>
                                            鏈接到第三方服務的條款和條件
                                            應用程序使用的提供商
                                        </ p>
                                        <ul><li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
                                        </ul>
                                        <ul> <li> <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer"> Google Play服務</a> </ li>
                                        </ ul>
                                        </div> 
                                        <p>
                                        You should be aware that there are certain things that
                                        Veefun Information Technology Limited will not take responsibility for. Certain
                                        functions of the app will require the app to have an active
                                        internet connection. The connection can be Wi-Fi, or provided
                                        by your mobile network provider, but Veefun Information Technology Limited
                                        cannot take responsibility for the app not working at full
                                        functionality if you don’t have access to Wi-Fi, and you don’t
                                        have any of your data allowance left.
                                        </p> 
                                        <p>
                                        您應該知道，有些事情
                                        Veefun Information Technology Limited將不承擔任何責任。 某些
                                        該應用程序的功能將要求該應用程序處於活動狀態
                                        網絡連接。 連接可以是Wi-Fi或已提供
                                        由您的移動網絡提供商提供，但Veefun Information Technology Limited
                                        無法對應用無法正常使用承擔責任
                                        功能，如果您無法訪問Wi-Fi，並且您沒有
                                        剩下您的任何數據津貼。
                                        </p>
                                        <p>
                                        If you’re using the app outside of an area with Wi-Fi, you
                                        should remember that your terms of the agreement with your
                                        mobile network provider will still apply. As a result, you may
                                        be charged by your mobile provider for the cost of data for
                                        the duration of the connection while accessing the app, or
                                        other third party charges. In using the app, you’re accepting
                                        responsibility for any such charges, including roaming data
                                        charges if you use the app outside of your home territory
                                        (i.e. region or country) without turning off data roaming. If
                                        you are not the bill payer for the device on which you’re
                                        using the app, please be aware that we assume that you have
                                        received permission from the bill payer for using the app.
                                        </p> 
                                        <p>
                                        如果您在Wi-Fi區域以外使用該應用程序，則表示
                                        應該記住，您與您的協議條款
                                        移動網絡提供商仍將適用。 結果，您可能
                                        由您的移動服務提供商收取以下數據的費用：
                                        訪問應用程序時的連接持續時間，或者
                                        其他第三方費用。 使用該應用程序即表示您接受
                                        任何此類費用（包括漫遊數據）的責任
                                        如果您在本國領土之外使用該應用程序，則需要付費
                                        （即區域或國家/地區），而無需關閉數據漫遊功能。 如果
                                        您不是您所使用的設備的付款人
                                        使用該應用程序時，請注意，我們假設您擁有
                                        已獲得付款人的使用該應用的許可。
                                        </ p>
                                        <p>
                                        Along the same lines, Veefun Information Technology Limited cannot always take
                                        responsibility for the way you use the app i.e. You need to
                                        make sure that your device stays charged – if it runs out of
                                        battery and you can’t turn it on to avail the Service,
                                        Veefun Information Technology Limited cannot accept responsibility.
                                        </p> 
                                        <p>
                                        同樣，Veefun Information Technology Limited不能總是採取
                                        您對應用程序使用方式的責任，即您需要
                                        如果設備用完了，請確保您的設備保持充電狀態
                                        電池，您將無法打開它以使用該服務，
                                        Veefun Information Technology Limited不承擔任何責任。
                                        </ p>
                                        <p>
                                        With respect to Veefun Information Technology Limited’s responsibility for your
                                        use of the app, when you’re using the app, it’s important to
                                        bear in mind that although we endeavour to ensure that it is
                                        updated and correct at all times, we do rely on third parties
                                        to provide information to us so that we can make it available
                                        to you. Veefun Information Technology Limited accepts no liability for any
                                        loss, direct or indirect, you experience as a result of
                                        relying wholly on this functionality of the app.
                                        </p> 
                                        <p>
                                        關於Veefun Information Technology Limited對您的責任
                                        使用該應用程序時，請務必注意
                                        請記住，儘管我們努力確保
                                        隨時更新和糾正，我們確實依賴第三方
                                        向我們提供信息，以便我們可以使用它
                                        給你。 Veefun Information Technology Limited對任何後果不承擔任何責任
                                        您因以下原因而遭受的直接或間接損失
                                        完全依賴於應用程序的此功能。
                                        </ p>
                                        <p>
                                        At some point, we may wish to update the app. The app is
                                        currently available on Android and iOS – the requirements for
                                        both systems(and for any additional systems we
                                        decide to extend the availability of the app to) may change,
                                        and you’ll need to download the updates if you want to keep
                                        using the app. Veefun Information Technology Limited does not promise that it
                                        will always update the app so that it is relevant to you
                                        and/or works with the Android and iOS version that you have
                                        installed on your device. However, you promise to always
                                        accept updates to the application when offered to you, We may
                                        also wish to stop providing the app, and may terminate use of
                                        it at any time without giving notice of termination to you.
                                        Unless we tell you otherwise, upon any termination, (a) the
                                        rights and licenses granted to you in these terms will end;
                                        (b) you must stop using the app, and (if needed) delete it
                                        from your device.
                                        </p> 
                                        <p>
                                        在某些時候，我們可能希望更新該應用程序。該應用程序是
                                        目前可在Android和iOS上使用-的要求
                                        這兩個系統（以及其他任何系統，
                                        決定將應用的可用性擴展到）可能會更改，
                                        如果要保留更新，則需要下載更新
                                        使用該應用程序。 Veefun Information Technology Limited不承諾
                                        會始終更新應用程序，以便與您相關
                                        和/或與您擁有的Android和iOS版本一起使用
                                        安裝在您的設備上。但是，您保證始終
                                        接受向您提供的應用程序更新，我們可能
                                        也希望停止提供該應用，並可能終止使用
                                        隨時向您發出終止通知。
                                        除非我們另行告知，否則在終止時，（a）
                                        這些條款授予您的權利和許可將終止；
                                        （b）您必須停止使用該應用程序，並且（如果需要）將其設備上刪除
                                        。
                                        </ p>
                                        <p><strong>Changes to This Terms and Conditions</strong></p>
                                        <p> <strong>對本條款和條件的更改</ strong> </ p> 
                                        <p>
                                        We may update our Terms and Conditions
                                        from time to time. Thus, you are advised to review this page
                                        periodically for any changes. We will
                                        notify you of any changes by posting the new Terms and
                                        Conditions on this page.
                                        </p> 
                                        <p>
                                        我們可能會更新我們的條款和條件
                                        時。 因此，建議您定期進行查看此頁面
                                        的更改。 我們會
                                        通過發布新條款將任何更改通知您，並
                                        此頁面上的條件。
                                        </ p>
                                        <p>
                                        These terms and conditions are effective as of 2020-07-05
                                        </p> 
                                        <p>
                                        這些條款和條件自2020年7月5日起生效
                                        </ p>
                                        <p><strong>Contact Us</strong></p> 
                                        <p> <strong>聯繫我們</ strong> </ p>
                                        <p>
                                        If you have any questions or suggestions about our
                                        Terms and Conditions, do not hesitate to contact us
                                        at support@lightpip.com.
                                        </p> 
                                        <p>
                                        如果您對我們的條款和條件有任何疑問或建議
                                        ，請隨時與我們聯繫
                                        請發送電子郵件至support@lightpip.com。
                                        </ p>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
      </section>
      {/*home section end*/}

      
      

      {/*Footer Section start*/}
      <footer className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="copyright-section">
                <p>2021 Copyright &copy; LightPip Limited</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*Footer Section End*/}
    </div>
  );
};

export default TermsConditions;
