import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const InFooter = (props) => {
 
  const { t, i18n } = useTranslation();

  return (
    <div>
        <section className="p-0">
        <div className="container-fluid">
          <div className="bottom-section">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="address-bar-inner" style={{backgroundColor : props.color}}>
                  <div>
                    <ul className="footer-style">
                        <li>
                            <div className="footer-address-inner" >
                                <a href={null} >LIGHTPIP LIMITED</a>
                            </div>
                        </li>
                        <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/locate.png`} alt="locate" />
                        </div>
                        <div className="footer-address-inner">
                            <a href={null}>{t('footer.address')}</a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/telephone.png`} alt="telephone" />
                        </div>
                        <div className="footer-address-inner">
                            <a href={null}>+852 39068520</a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/fotter-email.png`} alt="fotter-email" />
                        </div>
                        <div className="footer-address-inner">
                            <a href={null}>support@lightpip.com</a>
                        </div>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-0">
                <iframe  id="gmap_canvas" 
                src="https://maps.google.com/maps?q=8%20Wang%20Kwong%20Road,%20Kowloon%20Bay,%20Hong%20Kong+(LightPip)&t=&z=13&ie=UTF8&iwloc=&output=embed"                        
                frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                </iframe>
               </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright-section index-footer">
        <p>{t('footer.copyright')} &copy; LightPip Limited</p>
      </div>
    </div>
  );
};

export default InFooter;
