import React, { useEffect, useState } from "react";
import ContactNow from './ContactNow';
import InFooter from './InFooter';
import FeatureGraph from './FeatureGraph';
import HeaderTron from './HeaderTron';
import { useTranslation } from 'react-i18next';

const Coupon = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
       
        <HeaderTron color='#9EE5ED' video="https://videodelivery.net/48de1e17defca174976f81aa7c7ee120/manifest/video.m3u8" title={t('coupon.title')} desc="" />


        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#C4E1E4'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('coupon.row1.col1.title')}</b> </h2>
                            <p class="lead text-white">{t('coupon.row1.col1.desc')}</p>
                        </div>
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-2"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/c7fbe736-86e2-4327-e989-3400125de900/public"} width="1500"/> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#E2F2F3'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-1"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/613861e4-03ef-4126-e7d7-0705e8420f00/public"} width="1500"/> 
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('coupon.row2.col1.title')}</b> </h2>
                            <p class="lead">{t('coupon.row2.col1.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


       

        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#C4E1E4'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('coupon.row3.col1.title')}</b> </h2>
                            <p class="lead text-white">{t('coupon.row3.col1.desc')}</p>
                        </div>
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-2"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/7f3cae8e-a9a5-4e22-4ec5-bfd9d0d1db00/public"} width="1500"/> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5"   style={{backgroundColor : '#E2F2F3'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 p-md-4 col-3 offset-lg-1 order-1 order-md-1"> 
                            <img class="img-fluid d-block" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/a49e7b48-8e76-4311-54fe-83bda3360300/public"} width="1500"/> 
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1 py-4">
                            <h2 class="my-3"> <b>{t('coupon.row4.col1.title')}</b> </h2>
                            <p class="lead">{t('coupon.row4.col1.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid py-5 px-5" style={{backgroundColor : '#FFFFFF'}}>
            
            <div class="row">
                <div class="col-lg-6 col-md-6 p-5">
                    <div class="card h-100">
                        <div class="card-body p-5">
                        <div class="row">
                            <div class="col-md-12 "> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/1a287553-d745-48f3-0346-62f4517e6600/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0"></h3>
                            <p class="mt-3 mb-0">
                                                
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 p-5">
                    <div class="card " style={{backgroundColor : '#C4E1E4'}}>
                        <div class="card-body p-5">
                        <div class="row">
                           
                        </div>
                        <h2 class="mt-3 mb-0">{t('coupon.row5.col1.title')}</h2>
                        <p class="mt-3 mb-0">{t('coupon.row5.col1.desc')}
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FeatureGraph image="https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/d4c306e7-8e4b-4ae6-dd31-7acd2c96c700/public" />

        <ContactNow bcolor="#E2F2F3" fcolor="#C4E1E4"/>

        <InFooter color="#9EE5ED"/>


    </div>
  );
};

export default Coupon;
