import React, { useEffect } from "react";
import { Stream } from "@cloudflare/stream-react";
import ReactHlsPlayer from 'react-hls-player';

export default function Video() {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);


  const videoIdOrSignedUrl = "418671db68fc957b12963938310eda9d";

  return (
    <div>
    <section class="py-0 text-center text-white" style={{position:'relative',overflow:'hidden',backgroundColor:'#18E6D1'}}> 
          <ReactHlsPlayer
          src="https://videodelivery.net/418671db68fc957b12963938310eda9d/manifest/video.m3u8"
          autoPlay={true}
          controls={true}
          loop={false}
          width="85%"
          height="auto"
        />
     </section>
    </div>
  );
}
