import React, { useEffect, useState } from "react";
import ContactNow from './ContactNow';
import InFooter from './InFooter';
import FeatureGraph from './FeatureGraph';
import HeaderTron from './HeaderTron';
import { useTranslation } from 'react-i18next';

const MultiChannel = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
       
        <HeaderTron color={'#FBACAC'} video="https://videodelivery.net/5e0b508120ed4af9375b75f8ffe882b6/manifest/video.m3u8"
         title={t('multichannel.title')} desc={t('multichannel.description')}/>

        <section class="container-fluid py-5 px-5" style={{backgroundColor : '#F6B9B9'}}>
            <div class="row">
                <div class="text-center mx-auto col-md-12">
                <h2 class="text-white mb-4 text-right" >{t('stafflikeit')}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 p-3" >
                    <div class="card h-100" style={{backgroundColor : 'transparent', borderWidth : 0}}>
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                {/*
                                <img class="img-fluid d-block rounded" src={`${process.env.PUBLIC_URL}/assets/images/25.png`}/> 
                                */}
                            </div>
                        </div>
                        <h2 class="mt-3 mb-0">{t('multichannel.row1.col1.title')}</h2>
                        <p class="mt-3 mb-0">{t('multichannel.row1.col1.desc')}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/712ad8b7-b626-4b0c-36a1-49051d429500/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('multichannel.row1.col2.title')}</h3>
                        <p class="mt-3 mb-0">{t('multichannel.row1.col2.desc')}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 p-3">
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/d1f58a82-5a1e-4097-2db4-192e638af100/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('multichannel.row1.col3.title')}</h3>
                        <p class="mt-3 mb-0">{t('multichannel.row1.col3.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="text-center mx-auto col-md-12">
                <h2 class="text-white mt-4 text-left" style={{fontSize : 20}}>{t('customerloveit')}</h2>
                </div>
            </div>
        </section>

        <FeatureGraph image="https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/cd170aea-f1d7-4d63-5993-afd81db1bd00/public" />

        <ContactNow bcolor="#FEF2F2" fcolor="#FBACAC"/>

        <InFooter color="#FBACAC"/>


    </div>
  );
};

export default MultiChannel;
