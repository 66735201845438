import React, { useEffect } from "react";

export default function ThankYou() {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  let ios = "https://apps.apple.com/app/id1555013454"
  let android = "https://play.google.com/store/apps/details?id=com.lightpip.mobiapp"

  return (
    <div className="container-fluid px-0 py-0" style={{backgroundColor : '#C4E1E4'}}>
      <div className="thanks-bg">
        <div>
          <div className="col-md-12">
            <div className="thanks-section">
              <div>
                <img
                  src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/88152bf2-d264-4809-bf53-16b718114000/public"}
                  className="img-fluid downlod-img"
                  alt=""
                />
                <div className="col-lg-8 offset-lg-2">
                  <h2>Thank You</h2>
                </div>
                <h3>
                謝謝你的登記。由於參加人數眾多，我們將爭取於下個工作天完結前聯絡你。請留言電郵 <a href="mailto:support@lightpip.com">support@lightpip.com</a> 或 Whatsapp (90252080) 或 下面在線聊天。
                </h3>
              </div>
            </div>
            <div>
                <a href={ios}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`} alt="appstore" className="store" />
                </a>
                <a href={android}>
                    <img
                    className="ml-10 store"
                    src={`${process.env.PUBLIC_URL}/assets/images/play-store.png`}
                    alt="play-store"
                    />
                </a>
            </div>
          </div>
        </div>
      </div>
      {/*copy-right-section*/}
      <footer className="bg-thanks">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="copyright-section">
               
                <div className="mt-3 text-center">
                  <a
                    href={`${process.env.PUBLIC_URL}/`}
                    className="btn btn-custom theme-color theme-color pt-2 pb-2"
                  >
                    回到主頁
                  </a>
                </div>

                <p>
                  2021 Copyright &copy; LightPip Limited
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*end copy right section*/}
    </div>
  );
}
