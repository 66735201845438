import React, { useEffect, useState } from "react";
import ContactNow from './ContactNow';
import InFooter from './InFooter';
import FeatureGraph from './FeatureGraph';
import HeaderTron from './HeaderTron';
import { useTranslation } from 'react-i18next';

const SimpleSetup = () => {
 
    
  const { t, i18n } = useTranslation();
  

  return (
    <div>
       
        <HeaderTron color={'#FFECA8'} video="https://videodelivery.net/00200e4c3623b211afaf0c8e33213a37/manifest/video.m3u8" 
        title={t('simplesetup.title')} desc={t('simplesetup.description')} />

        <section class="container-fluid py-5 px-5" style={{backgroundColor : '#F9C65E'}}>
            <div class="row">
                <div class="text-center mx-auto col-md-12">
                <h2 class="text-white mb-4 text-right" >{t('stafflikeit')}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 p-3" >
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/318285fe-2669-4d92-b953-32f3e3c34f00/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('simplesetup.row1.col1.title')}</h3>
                        <p class="mt-3 mb-0">{t('simplesetup.row1.col1.desc')}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 p-3">
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/3aa14617-a537-4f90-109a-d3f1ef894800/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('simplesetup.row1.col2.title')}</h3>
                        <p class="mt-3 mb-0">{t('simplesetup.row1.col2.desc')}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 p-3">
                    <div class="card h-100">
                        <div class="card-body p-4">
                        <div class="row">
                            <div class="col-md-12 col-3"> 
                                <img class="img-fluid d-block rounded" src={"https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/76395c86-c5b8-47d1-5e02-a4d07e794500/public"}/> 
                            </div>
                        </div>
                        <h3 class="mt-3 mb-0">{t('simplesetup.row1.col3.title')}</h3>
                        <p class="mt-3 mb-0">{t('simplesetup.row1.col3.desc')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="text-center mx-auto col-md-12">
                <h2 class="text-white mt-4 text-left" style={{fontSize : 20}}>{t('customerloveit')}</h2>
                </div>
            </div>
        </section>

        <FeatureGraph image="https://imagedelivery.net/BbAYG6w3TA2DLktEaHrAVQ/86422faf-b195-4e8c-c039-0b1bfb523700/public" />

        <ContactNow bcolor="#FDFBF1" fcolor="#FFE274"/>

        <InFooter color="#FFECA8"/>


    </div>
  );
};

export default SimpleSetup;
